.animate-character {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.image-list-item {
  transition: transform 0.3s;
}

.image-list-item:hover {
  transform: scale(1.1);
}

.image {
  transition: transform 0.3s;
}

.image:hover {
  transform: scale(1.1);
}
